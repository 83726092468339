import { StateCreator } from "zustand"
import { DataTablesSlice } from '../slice/datatables'
import dataTablesDev from '../setup/datatables-dev.json'
import dataTablesProd from '../setup/datatables-prod.json'
import { dataTablesApi } from "../../client/datatables"
import { DataTableProps, DataTableItem } from '../../types/datatables'

const generateInitialDataTableValues = () => {
  return (process.env.NODE_ENV === 'development')
    ? dataTablesDev
    : dataTablesProd
}

const dataTableInitialValues = generateInitialDataTableValues()

const mergeFunction = (dataTables: { [key: string]: DataTableProps},
  newItems:  { [key: string]: DataTableItem[] }) => {
    let newDataTables = {
      ...dataTables
    }
    for (var tableName in newItems) {
      newDataTables[tableName] = {
        ...dataTables[tableName],
        items: newItems[tableName]
      }
    }
    return newDataTables
}

export const createDataTablesSlice: StateCreator<
    DataTablesSlice,
    [['zustand/devtools', never]],
    [],
    DataTablesSlice> = (set) => ({
  dataTables: dataTableInitialValues,

  fetchDataTableItems: async () => set((state) => {
    let newState = {
      ...state
    }
    dataTablesApi.fetchTableItems().then((obj: any) => {
      const newItems = obj as { [key: string]: DataTableItem[] }
      newState =  {
        ...state,
        dataTables: mergeFunction(state.dataTables, newItems)
      }
    })
    return newState
  }), 

  setDataTableItems: (newItems: { [key: string]: DataTableItem[] }) => set((state) => {
    return  {
      ...state,
      dataTables: mergeFunction(state.dataTables, newItems)
    }
  }), 

  appendReport: (props: DataTableItem) => set((state) => {
    return {
      ...state,
      dataTables: {
        ...state.dataTables,
        reports: {
          ...state.dataTables.reports,
          items: [ ...state.dataTables.reports.items, props]
        }
      }
    }
  }),

  deleteReport: (props: DataTableItem) => set((state) => {
    const newItems = state.dataTables.reports.items
    return {
      ...state,
      dataTables: {
        ...state.dataTables,
        reports: {
          ...state.dataTables.reports,
          items: [
            ...newItems
          ]
        }
      }
    }
  }),

  updateReportViews: (props: DataTableItem, views?: any, viewsString?: string) => set((state) => {
    const reports = state.dataTables.reports.items
    const rIndex = reports.findIndex((item: DataTableItem) => (item.id === props.id))
    const modifiedReportProps = {
      ...props,
      views: views,
      viewsString: viewsString
    } as DataTableItem
    const mergedReports = [
      ...reports.slice(0, rIndex),
      {
        ...modifiedReportProps,
      },
      ...reports.slice(rIndex+1),
    ]
    console.log(mergedReports)

    return {
      ...state,
      dataTables: {
        ...state.dataTables,
        reports: {
          ...state.dataTables.reports,
          items: mergedReports,
        }
      }
    }
  }),
})
