import { Group, Title, ActionIcon, useMantineColorScheme } from '@mantine/core';
import { IconSun, IconMoonStars } from '@tabler/icons-react'
import { Logo } from './logo'
import { UserMenu } from './usermenu'

export const Header = (props: any) => {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();

  return (
    <Group sx={{ height: '100%' }} px={20} position="apart">
      <Logo colorScheme={colorScheme} />
      <Title order={3}>VCollab Enterprise Dashboard</Title>
      <Group>
        <ActionIcon
          variant="default"
          onClick={() => toggleColorScheme()}
          size={30}
        >
          {
            colorScheme === 'dark'
              ? <IconSun size={16} />
              : <IconMoonStars size={16} />
          }
        </ActionIcon>
        <UserMenu />
      </Group>
    </Group>
  )
}

export default Header
