import { useState, useEffect } from 'react'
import sortBy from 'lodash/sortBy'
import { Stack, Title, Center, Card } from '@mantine/core';
import { DataTable as DataTableBase, DataTableSortStatus } from 'mantine-datatable';
import { DataTableType, DataTableItem } from '../../types/datatables'
import { useStore } from '../../store'

interface Props {
  tableName: DataTableType
}

export const DataTable = ({tableName}: Props) => {
  const PAGE_SIZE = 8
  const { dataTables, selectTableItem } = useStore()
  const [sortStatus, setSortStatus] = useState<DataTableSortStatus>({ columnAccessor: 'name', direction: 'asc' })
  const [page, setPage] = useState(1)
  const [records, setRecords] = useState(sortBy(dataTables[tableName].items, 'name').slice(0, PAGE_SIZE))

  useEffect(() => {
    const dataAsc = sortBy(dataTables[tableName].items, sortStatus.columnAccessor) as DataTableItem[];
    const data = (sortStatus.direction === 'desc' ? dataAsc.reverse() : dataAsc);
    const from = (page - 1) * PAGE_SIZE;
    const to = from + PAGE_SIZE;
    setRecords(data.slice(from, to));
  }, [dataTables, tableName, page, sortStatus])

  return (
    <Stack>
      <Center>
        <Title order={4}>
          {dataTables[tableName].title}
         </Title>
      </Center>
      <Card shadow="sm" p="lg" radius="md" withBorder>
        <DataTableBase
          withBorder
          borderRadius="sm"
          withColumnBorders
          striped
          highlightOnHover
          textSelectionDisabled
          onPageChange={(p)=>setPage(p)}
          columns={dataTables[tableName].columns as any}
          records={records}
          totalRecords={dataTables[tableName].items.length}
          recordsPerPage={PAGE_SIZE}
          page={page}
          sortStatus={sortStatus}
          onSortStatusChange={setSortStatus}
          onRowClick={(item) =>
            selectTableItem(tableName, item as DataTableItem)
          }
          sx={(theme) => ({
            border: `1px solid ${theme.colors.blue[6]}`,
            borderRadius: theme.radius.md,
          })}
        />
      </Card>
    </Stack>
  )
}

export default DataTable