import React, { useContext, useState, useEffect } from 'react'
import { Session, User } from "@supabase/gotrue-js/src/lib/types"
import { apiClient } from '../client/supabase'

export const AuthContext = React.createContext<{
    user: User | null, session: Session | null}>({
  user: null, session: null
})

export const AuthContextProvider = (props: any) => {
  const [session, ] = useState<Session | null>(null)
  const [user, setUser] = useState<User | null>(null)

  useEffect(() => {
    apiClient.auth.getSession().then(({data: {session}}) => {
      setUser(session?.user ?? null)
    })

    const { data: authListener } = apiClient.auth.onAuthStateChange(
      async (event, session) => {
        setUser(session?.user ?? null)
      }
    )

    return () => {
      authListener.subscription.unsubscribe()
    }
  }, [])

  const value = {
    session: session,
    user: user,
  }

  return <AuthContext.Provider value={value} {...props} />
}

export const useAuth = () => {
  const context =  useContext(AuthContext)
  if (context === undefined)
    throw new Error('useAuth must be used within a AuthContextProvider.')
  return context;
}
