import { DataTableItem, DataTableType } from '../../../types/datatables'
import { apiClient, getSupabaseDetails } from '../../../client/supabase'

const VIEWER_LOCATION: string = (process.env.REACT_APP_VIEWER_LOCATION as string)
const API_LOCATION: string = encodeURI(process.env.REACT_APP_API_LOCATION as string)
const URL_PREFIX: string = encodeURI(process.env.REACT_APP_URL_PREFIX as string)

export const getUserRole = async (userId: string) => {
  const purpose = 'enterprise_gui'
  const userRolesTable = `user_roles_${purpose}`
  const response = await apiClient.from(userRolesTable)
                                      .select('user_id, role_id')
                                      .eq('user_id', userId)
  if(response?.data?.length && response?.data?.length > 0)
    return response.data[0]?.role_id
  return null
}

export const getDataTableItem = (items: { [key: string]: DataTableItem[] },
                type: DataTableType, id: number) => {
  const matchingItems = items[type].filter(function (item) { return item.id === id })
  if(matchingItems.length === 0) {
    console.log(`Unable to identify the ${type} corresponding to the id ${id}`)
    return undefined
  }
  return matchingItems[0]
}

export const getReportLink  = (report?: DataTableItem, userRole? : string) => {
  if(!userRole)
    userRole ="analyst"; //temp fix need to be removed
  if (report?.url && userRole)
  {
      const { SUPABASE_URL, SUPABASE_KEY } =  getSupabaseDetails()
      const viewerUrl = `${VIEWER_LOCATION}?serverurl=${SUPABASE_URL}&apikey=${SUPABASE_KEY}&roleid=${userRole}&reportid=${report.id}&api=${API_LOCATION}&url=${URL_PREFIX}${encodeURI(report.url)}#/`
      return viewerUrl
  }

  return undefined
}

export const newReportNameInvalid = (name: string, reports: DataTableItem[]): boolean => {
  return !reports.every(item => (item.name !== name))
}
