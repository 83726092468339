import { Card, Group, Stack, Text, Button, Stepper, Space, TextInput } from '@mantine/core'
import { DataOperationType } from '../../../types/dataoperations'
import { DataTableItem } from '../../../types/datatables'
import { useStore } from "../../../store"
import { dataTablesApi } from '../../../client/datatables'

export const DeleteReport = (props: any) => {
  const { dataOperationActiveStep, clearDataOperation,
          selectedReport, setDataTableItems } = useStore()

  const finishCallback = (selectedReport?: DataTableItem) => {
    if (!selectedReport) {
      console.log("Invalid report selection")
      return
    }

    dataTablesApi.deleteReport(selectedReport.id).then((out: any) => {
      dataTablesApi.fetchTableItems().then(async (newItems: { [key: string]: DataTableItem[] }) => {
        setDataTableItems(newItems)
        clearDataOperation()
      })
    })
  }

  return (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      <Card.Section withBorder inheritPadding py="xs">
        <Group position="apart">
          <Text weight={500}>Delete an existing report</Text>
          <Button onClick={() => clearDataOperation()}>Cancel</Button>
        </Group>
      </Card.Section>
      <Card.Section inheritPadding mt="lg" pb="md">
        <Stepper active={dataOperationActiveStep[DataOperationType.Delete]} breakpoint="sm">
          <Stepper.Step label="Select report" description="Select a report from the reports list">
          </Stepper.Step>
          <Stepper.Step label="Delete report" description={"Click the button to delete report in a new window"}>
            <Stack>
              <Space h="md" />
              <Group position="apart">
                <Group>
                  <Text>Selected report is</Text>
                  <TextInput value={selectedReport?.name} disabled />
                </Group>
                <Button
                  onClick={
                    () => finishCallback(selectedReport)
                  }
                >
                  Click to delete selected report
                </Button>
              </Group>
            </Stack>
          </Stepper.Step>
        </Stepper>
      </Card.Section>
    </Card>
  )
}

export default DeleteReport
