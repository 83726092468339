import React from 'react';
import { MantineProvider, ColorSchemeProvider, ColorScheme } from '@mantine/core'
import { useHotkeys, useLocalStorage } from '@mantine/hooks'
import { Router } from './components/shell'

export default function App() {
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'mantine-color-scheme',
    defaultValue: 'dark',
    getInitialValueInEffect: true,
  })

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'))

  useHotkeys([['mod+J', () => toggleColorScheme()]])

  return (
    <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
      <MantineProvider theme={{ colorScheme }} withGlobalStyles withNormalizeCSS>
        <Router />
      </MantineProvider>
    </ColorSchemeProvider>
  )
}
