import { useState } from 'react'
import { Card, Group, Stack, Text, Button, Stepper, Space, TextInput } from '@mantine/core'
import { DataOperationType } from '../../../types/dataoperations'
import { DataTableItem, DataTableType } from '../../../types/datatables'
import { useStore } from "../../../store"
import { dataTablesApi } from '../../../client/datatables'
import { getDataTableItem, getReportLink } from './methods'
import { debugMessage } from '../../../util'

export const CreateReport = (props: any) => {
  const [reportName, setReportName] = useState("")
  const { dataOperationActiveStep, clearDataOperation,
          selectedModel,
          setDataTableItems, userRole } = useStore()

  const finishCallback = (selectedModel?: DataTableItem,
                                reportName?: string) => {
    if (!selectedModel) {
      console.log("Invalid model selection")
      return
    }
    if (!reportName) {
      console.log("Empty report name")
      return
    }

    dataTablesApi.createNewReport(selectedModel, reportName).then((newReport?: DataTableItem) => {
      if (!newReport)
        return

      const reportId = newReport?.id
      dataTablesApi.fetchTableItems().then(async (newItems: { [key: string]: DataTableItem[] }) => {
        setDataTableItems(newItems)
        const report = getDataTableItem(newItems, DataTableType.Reports, reportId)
        const reportUrl = getReportLink(report, userRole)
        if (reportUrl) {
          debugMessage(`Viewer URL: ${reportUrl}`)
          window.open(reportUrl,  "_blank")
          clearDataOperation()
        }
      })
    })
  }

  return (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      <Card.Section withBorder inheritPadding py="xs">
        <Group position="apart">
          <Text weight={500}>Create a new report</Text>
          <Button onClick={() => clearDataOperation()}>Cancel</Button>
        </Group>
      </Card.Section>
      <Card.Section inheritPadding mt="lg" pb="md">
        <Stepper active={dataOperationActiveStep[DataOperationType.Create]} breakpoint="sm">
          <Stepper.Step label="Select model" description="Select a model from the models list">
          </Stepper.Step>
          <Stepper.Step label="Specify name and open" description="Provide a name for the new report and click the button to open it">
            <Stack>
              <Space h="md" />
              <Group>
                <Text>Selected model is</Text>
                <TextInput value={selectedModel?.name} disabled />
              </Group>
              <Group position="apart">
                <Group>
                  <Text>Enter a name for the new report</Text>
                  <TextInput
                    placeholder="Report Name"
                    value={reportName}
                    onChange={(event) => setReportName(event.currentTarget.value)}
                  />
                </Group>
                <Button
                  onClick={
                    () => finishCallback(selectedModel, reportName)
                  }
                >
                  Click to create a new report
                </Button>
              </Group>
            </Stack>
          </Stepper.Step>
        </Stepper>
      </Card.Section>
    </Card>
  )
}

export default CreateReport
