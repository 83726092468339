export enum DataTableType {
  Models = "models",
  Reports = "reports",
}

export interface DataTableItem {
  id: number
  name: string
  createdAt: string
  model: number
  url: string
  views?: any
  viewsString?: string
  owner: string
}

export interface DataTableColumnProps {
  accessor: string
  title?: string
  textAlignment?: string
}

export interface DataTableProps {
  title: string
  items: DataTableItem[]
  columns: DataTableColumnProps[]
}
