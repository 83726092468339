import { useEffect } from 'react'
import { SimpleGrid, Stack } from '@mantine/core'
import { DataTable } from './datatable'
import { DataTableType, DataTableItem } from '../../types/datatables'
import { useStore } from '../../store'
import { DataOperationsFlow } from './dataoperations'
import { DataOperationType } from '../../types/dataoperations'
import { useAuth } from '../../context/auth'
import { dataTablesApi } from '../../client/datatables'
import { getUserRole } from './dataoperations/methods'

export const Dashboard = (props: any) => {
  const { setDataTableItems, setUserRole } = useStore()
  const { user }= useAuth()

  useEffect(() => {
    if (user?.id) {
      getUserRole(user?.id).then((userRole?: string) => {
        if (userRole) {
          setUserRole(userRole)
        }
      })
    }
    dataTablesApi.fetchTableItems().then((newItems: { [key: string]: DataTableItem[] }) => {
      setDataTableItems(newItems)
    })
  }, [setDataTableItems, setUserRole, user?.id])

  return (
    <Stack>
      <SimpleGrid cols={2}>
        <DataTable tableName={DataTableType.Models} />
        <DataTable tableName={DataTableType.Reports} />
      </SimpleGrid>
      <DataOperationsFlow state={DataOperationType.None} />
    </Stack>
  )
}

export default Dashboard
