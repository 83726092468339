import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { RootScreen, Login, DevTest } from '../routes'
import { AuthContextProvider, useAuth } from '../../context/auth'

const PrivateRoute = ({ children }: any) => {
  const {user}= useAuth()
  return user
    ? <>{children}</>
    : <Navigate to="/login" />
}

const LoginToDashboard = () => {
  const {user}= useAuth()
  return user
    ? <Navigate to="/" />
    : <Login />
}

export const Router = (props: any) => {

  return (
    <BrowserRouter>
      <AuthContextProvider>
      <Routes>
          <Route path="/"
            element={
              <PrivateRoute>
                <RootScreen />
              </PrivateRoute>
            }
          />
          { (process.env.NODE_ENV === 'development') &&
            <Route path="/devtest"
              element={
                  <DevTest />
              }
            />
          }
          <Route path="/login" element={<LoginToDashboard />} />
      </Routes>
    </AuthContextProvider>
    </BrowserRouter>
  )
}

export default Router
