import { Center, Group, Button } from '@mantine/core'
import { DataOperationType } from '../../../types/dataoperations'
import { useStore } from "../../../store"

export const DataOperationControls = (props: any) => {
  const { startDataOperation } = useStore();

  return (
    <Center>
      <Group>
        <Button onClick={() => startDataOperation(DataOperationType.AddModel)}>
          Add a model
        </Button>
        <Button onClick={() => startDataOperation(DataOperationType.Create)}>
          Create a report
        </Button>
        <Button onClick={() => startDataOperation(DataOperationType.Open)}>
          Open a report
        </Button>
        <Button onClick={() => startDataOperation(DataOperationType.Compose)}>
          Compose a report
        </Button>
        <Button onClick={() => startDataOperation(DataOperationType.Delete)}>
          Delete a report
        </Button>
        { (process.env.NODE_ENV === 'development') &&
            <Button onClick={() => startDataOperation(DataOperationType.UpdateViews)}>
            Update Views
            </Button>
        }
      </Group>
    </Center>
  )
}

export default DataOperationControls
