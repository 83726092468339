import { forwardRef } from 'react'
import { UnstyledButton, Text, Box } from '@mantine/core'

interface UserButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  name: string
  email: string
}

export const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>(
  ({ name, email,  ...others }: UserButtonProps, ref) => (
    <Box>
      <UnstyledButton
        ref={ref}
        sx={(theme) => ({
          display: 'block',
          width: '100%',
          padding: theme.spacing.xs,
          borderRadius: theme.radius.sm,
          color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

          '&:hover': {
            backgroundColor:
              theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
          },
        })}
        {...others}
      >
        <Text size="sm" weight={500}
        >
          {email}
        </Text>
      </UnstyledButton>
    </Box>
  )
)

export default UserButton
