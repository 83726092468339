import { Card, Group, Stack, Text, Button, Stepper, Space, TextInput } from '@mantine/core'
import { DataOperationType } from '../../../types/dataoperations'
import { DataTableItem, DataTableType } from '../../../types/datatables'
import { useStore } from "../../../store"
import { dataTablesApi } from '../../../client/datatables'
import { getDataTableItem, getReportLink } from './methods'
import { debugMessage } from '../../../util'

export const OpenReport = (props: any) => {
  const { dataOperationActiveStep, clearDataOperation,
          selectedReport,
          setDataTableItems, userRole } = useStore()

  const finishCallback = (selectedReport?: DataTableItem) => {
    if (!selectedReport) {
      console.log("Invalid report selection")
      return
    }

    dataTablesApi.fetchTableItems().then((newItems: { [key: string]: DataTableItem[] }) => {
      setDataTableItems(newItems)
      const report = getDataTableItem(newItems, DataTableType.Reports, selectedReport.id)
      const reportUrl = getReportLink(report, userRole)
      if (reportUrl) {
        debugMessage(`Viewer URL: ${reportUrl}`)
        window.open(reportUrl,  "_blank")
        clearDataOperation()
      }
    })
  }

  return (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      <Card.Section withBorder inheritPadding py="xs">
        <Group position="apart">
          <Text weight={500}>Open an existing report</Text>
          <Button onClick={() => clearDataOperation()}>Cancel</Button>
        </Group>
      </Card.Section>
      <Card.Section inheritPadding mt="lg" pb="md">
        <Stepper active={dataOperationActiveStep[DataOperationType.Open]} breakpoint="sm">
          <Stepper.Step label="Select report" description="Select a report from the reports list">
          </Stepper.Step>
          <Stepper.Step label="Open report" description={"Click the button to open report in a new window"}>
            <Stack>
              <Space h="md" />
              <Group position="apart">
                <Group>
                  <Text>Selected report is</Text>
                  <TextInput value={selectedReport?.name} disabled />
                </Group>
                <Button
                  onClick={
                    () => finishCallback(selectedReport)
                  }
                >
                  Click to open selected report
                </Button>
              </Group>
            </Stack>
          </Stepper.Step>
        </Stepper>
      </Card.Section>
    </Card>
  )
}

export default OpenReport
