import { useState } from 'react'
import { TextInput, PasswordInput, Paper, Title, Container, Button, Text } from '@mantine/core'
import { Navigate, useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/auth'
import { authApi } from '../../client/auth'
  
export const Login = () => {
  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')

  const { user } = useAuth()
  const navigate = useNavigate()

  function loginCallback(email: string, password: string) {
    authApi.signIn({ email, password }).then(({error}: any) => {
      if (error) {
        console.log('Error signing in: ')
        console.log(error)
      }
      else {
        navigate('/')
      }
    })
  }

  return user ?
    <Navigate to='/userprofile' /> :
    <Container size={420} my={40}>
      <Title
        align="center"
        sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
      >
        VCollab Enterprise
      </Title>

      <Text color="dimmed" size="sm" align="center" mt={5}>
        Please signin using your email and password
      </Text>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <TextInput label="Email" placeholder="you@vcollab.com" required
          onChange={(e) => setEmail(e.currentTarget.value)}
        />
        <PasswordInput label="Password" placeholder="Your password" required mt="md"
          onChange={(e) => setPassword(e.currentTarget.value)}
        />
        <Button fullWidth mt="xl"
          onClick={(e) => {
            e.preventDefault()
            loginCallback(email, password)
          }}
        >
          Sign in
        </Button>
      </Paper>
    </Container>
}

export default Login