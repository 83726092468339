import { Menu } from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import { UserButton } from './userbutton'
import { useAuth } from '../../context/auth'
import { authApi } from '../../client/auth'

export const UserMenu = () => {
  const {user} = useAuth()
  const navigate = useNavigate()

  const signOutHandler = () => {
    authApi.signOut().then((data: any) => {
      const {error} = data;
      if (error) {
        console.log('Error signing out: ')
        console.log(error)
      }
      navigate('/login')
    })
  }

  return (
    <Menu trigger="hover">
      <Menu.Target>
        <UserButton
          name=""
          email={user?.email??"you@vcollab.com"}
        />
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item onClick={()=>{signOutHandler()}}>Logout
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}

export default UserMenu
