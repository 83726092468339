import { DataOperationControls } from './controls'
import { OpenReport } from './openreport'
import { CreateReport } from './createreport'
import { ComposeReport } from './composereport'
import { DeleteReport } from './deletereport'
import { UpdateViews } from './updateviews'
import { Addmodel } from './addmodel'
import { DataOperationType } from '../../../types/dataoperations'
import { useStore } from '../../../store'

export const DataOperationsFlow = (props: any) => {
  const { activeDataOperation } = useStore();

  const dataOperationComponent = {
    [DataOperationType.None]: <DataOperationControls />,
    [DataOperationType.Create]: <CreateReport />,
    [DataOperationType.Open]: <OpenReport />,
    [DataOperationType.Compose]: <ComposeReport />,
    [DataOperationType.Delete]: <DeleteReport />,
    [DataOperationType.UpdateViews]: <UpdateViews />,
    [DataOperationType.AddModel]: <Addmodel />,
  };

  return dataOperationComponent[activeDataOperation];
}

export default DataOperationsFlow
