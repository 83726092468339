import { create } from "zustand";
import { devtools } from 'zustand/middleware'
import { DataOperationsSlice } from './slice/dataoperations'
import { createDataOperationsSlice } from './slicecreator/dataoperations'
import { DataTablesSlice } from './slice/datatables'
import { createDataTablesSlice } from './slicecreator/datatables'

export const useStore = create<DataTablesSlice & DataOperationsSlice>()(devtools((...a) => ({
  ...createDataTablesSlice(...a),
  ...createDataOperationsSlice(...a),
})))