import { apiClient } from './supabase'

export interface Role {
  name?: string
  title?: string
  permissions?: any
}

export interface RolePermissions {
  userId?: string,
  role?: Role
  error?: any
}

export const userApi = {
  getuserRole: async function(purpose: string, userId: string) {
    const userRolesTable = `user_roles_${purpose}`
    const rolePermissionsTable = `roles_permissions_${purpose}`
    const resp0 = await apiClient.from(userRolesTable)
                                        .select('user_id, role_id')
                                        .eq('user_id', userId)
    const data0 = resp0.data
    const error0 = resp0.error

    if(error0) {
      return {
        userId: userId,
        error: error0
      } as RolePermissions
    }
    else if(!data0 || data0.length === 0) {
      return {
        userId: userId,
        error: 'Empty user roles data'
      } as RolePermissions
    }
    else if(data0.length > 1) {
      return {
        userId: userId,
        error: 'Multiple rows for user roles data'
      } as RolePermissions
    }
    else {
      const roleName = data0[0].role_id
      const resp1 = await apiClient.from(rolePermissionsTable)
                                        .select('role_title, permissions')
                                        .eq('role_name', roleName)
      const data1 = resp1.data
      const error1 = resp1.error
      if(error1) {
        return {
          userId: userId,
          role: {
            name: roleName,
          },
          error: error1
        } as RolePermissions
      }
      else if(!data1 || data1.length === 0) {
        return {
          userId: userId,
          role: {
            name: roleName,
          },
          error: 'Empty role permissions data'
        } as RolePermissions
      }
      else if(data1.length > 1) {
        return {
          userId: userId,
          role: {
            name: roleName,
          },
          error: 'Multiple rows for role permissions data'
        } as RolePermissions
      }
      else {
        return {
          userId: userId,
          role: {
            name: roleName,
            title: data1[0].role_title,
            permissions: data1[0].permissions,
          },
        } as RolePermissions
      }
    }
  },

}