import { useState } from 'react'
import { Card, Group, Stack, Text, Button, Stepper, Space, TextInput } from '@mantine/core'
import { DataOperationType } from '../../../types/dataoperations'
import { DataTableItem } from '../../../types/datatables'
import { useStore } from "../../../store"
import { dataTablesApi } from '../../../client/datatables'

export const UpdateViews = (props: any) => {
  const [modifiedViews, setModifiedViews] = useState("")
  const { dataOperationActiveStep, clearDataOperation,
          selectedReport, setDataTableItems } = useStore()

  const finishCallback = (selectedReport?: DataTableItem,
                                newViews?: string) => {
    if (!selectedReport) {
      console.log("Invalid report selection")
      return
    }

    dataTablesApi.updateReportViews(selectedReport, newViews).then((out: any) => {
      dataTablesApi.fetchTableItems().then(async (newItems: { [key: string]: DataTableItem[] }) => {
        setDataTableItems(newItems)
        clearDataOperation()
      })
    })
  }

  return (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      <Card.Section withBorder inheritPadding py="xs">
        <Group position="apart">
          <Text weight={500}>Update report views</Text>
          <Button onClick={() => clearDataOperation()}>Cancel</Button>
        </Group>
      </Card.Section>
      <Card.Section inheritPadding mt="lg" pb="md">
        <Stepper active={dataOperationActiveStep[DataOperationType.UpdateViews]} breakpoint="sm">
          <Stepper.Step label="Select report" description="Select a report from the reports list">
          </Stepper.Step>
          <Stepper.Step label="Input new views" description="Enter the JSON string for modified report views and click the button to open it">
            <Stack>
              <Space h="md" />
              <Group>
                <Text>Selected report is</Text>
                <TextInput value={selectedReport?.name} disabled />
              </Group>
              <Group position="apart">
                <Group>
                  <Text>Enter the JSON string for modified report views</Text>
                  <TextInput
                    placeholder="Modified Views"
                    value={modifiedViews}
                    onChange={(event) => setModifiedViews(event.currentTarget.value)}
                  />
                </Group>
                <Button
                  onClick={
                    () => finishCallback(selectedReport, modifiedViews)
                  }
                >
                  Click to update report views
                </Button>
              </Group>
            </Stack>
          </Stepper.Step>
        </Stepper>
      </Card.Section>
    </Card>
  )
}

export default UpdateViews
