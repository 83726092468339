import { useState, useRef  } from 'react'
import { Card, Group, Text, Button, FileButton } from '@mantine/core'
import { useStore } from '../../../store'
import { DataTableItem } from '../../../types/datatables'
import { dataTablesApi } from '../../../client/datatables'
import { useAuth } from '../../../context/auth'
import axios from 'axios'
const API_LOCATION: string = process.env.REACT_APP_API_LOCATION as string;

export const Addmodel = (props: any) => {
    const { clearDataOperation, setDataTableItems } = useStore()
    const [file, setFile] = useState<File | null>(null);
    const resetRef = useRef<() => void>(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadedFileStatus, setUploadedFileStatus] = useState(null);
    const [error, setError] = useState<DOMException | null>();
    const {user} = useAuth()

    const resetState = () => {
        setUploadProgress(0);
        setUploadedFileStatus(null);
        setError(null);       
    };

    const clearFile = () => {
        resetState();
        setFile(null);
        //resetRef.current?.();
    };

    const onFileUpload = () => {
        if(file){
            const url = API_LOCATION.replace("model","upload");
            const formData = new FormData();
            formData.append("caxfile", file, file.name);

            const config = {
                headers: {
                  'content-type': 'multipart/form-data',
                },
                onUploadProgress: function(progressEvent : any) {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                  }
            };

            const modelName = file.name.replace(/\.[^/.]+$/, "");
            const modelType = 'vcollab_cax';
            const filePath = "samples/" + file.name;
            axios.post(url, formData, config)
            .then(async (response) => {
                const responseData = response.data
                console.log(responseData);     
                if(responseData && responseData.code === 1){                   
                    const remotefileData = await dataTablesApi.addNewmodel(modelName, modelType, filePath, user?.id??"");
                    if(remotefileData && remotefileData.length > 0) {
                        setUploadedFileStatus(responseData.message); 
                        dataTablesApi.fetchTableItems().then(async (newItems: { [key: string]: DataTableItem[] }) => {
                            setDataTableItems(newItems)
                          });
                    }                   
                    else
                        setError(new DOMException("Data insert function to backend resulted in error:")); 
                }                 
                else
                    setError(new DOMException(responseData.message));                    
                
            })
            .catch((error) => {
                console.error("Error uploading file: ", error);
                setError(error);
            });
        }
    };

    const onFileSelect = (selectedFile : File | null) => {
        if(selectedFile){
            resetState();
            setFile(selectedFile);
        }       
    };

    return (
        <Card shadow="sm" p="lg" radius="md" withBorder>
        <Card.Section withBorder inheritPadding py="xs">
            <Group position="apart">
            <Text weight={500}>Add model file</Text>
            <Button onClick={() => clearDataOperation()}>Cancel</Button>
            </Group>
        </Card.Section>
        <Card.Section inheritPadding mt="lg" pb="md">
            {!file && (
                <Group position="center">
                    <FileButton resetRef={resetRef} onChange={onFileSelect} accept=".cax">
                    {(props) => <Button {...props}>Upload Model</Button>}
                    </FileButton>
                </Group>
            )} 

            {file && (                
                <>                                
                    <Text size="sm" ta="center" mt="sm">Selected file Details</Text>
                    <Text size="sm" ta="center" mt="sm">Name : {file.name}</Text>
                    <Text size="sm" ta="center" mt="sm">Size : {file.size}</Text>
                    <div style={{ display: "flex",  flexDirection: "column", alignItems: "center", height: "120px",
    justifyContent: "space-evenly" }}>
                        {!(uploadedFileStatus || error) && <Button style={{width : '100px'}}  onClick={onFileUpload}>Upload</Button>  }
                        <Button style={{width : '100px'}} color="red" onClick={clearFile}>Reset</Button>
                        <progress value={uploadProgress} max="100"></progress>
                      
                        {uploadedFileStatus && <p style={{ color : 'blue'}}>{uploadedFileStatus}</p>}
                        {error && <p style={{ color : 'red'}}>Error : {error?.message}</p>}
                    </div>        
                </>   
            )} 
        </Card.Section>
        </Card>
    )
}

export default Addmodel;
