import { AppShell, Header } from '@mantine/core';
import { Header as HeaderContent } from '../elements/header'
import { Dashboard } from '../elements/dashboard'

export const RootScreen = (props: any) => {

  return (
    <AppShell
      padding="md"
      header={
        <Header height={60} p="xs">
          <HeaderContent />
        </Header>
      }
    >
        <Dashboard />
    </AppShell>
  )
}

export default RootScreen
