import { StateCreator } from "zustand";
import { DataOperationType } from "../../types/dataoperations";
import { DataOperationsSlice } from '../slice/dataoperations'
import { DataTableItem, DataTableType } from '../../types/datatables'

const stepsInOperation = {
    [DataOperationType.None]: 0,
    [DataOperationType.Open]: 2,
    [DataOperationType.Create]: 2,
    [DataOperationType.Compose]: 3,
    [DataOperationType.Delete]: 2,
    [DataOperationType.UpdateViews]: 2,
}

const noOpStepValues = {
    [DataOperationType.None]: 0,
    [DataOperationType.Open]: 0,
    [DataOperationType.Create]: 0,
    [DataOperationType.Compose]: 0,
    [DataOperationType.Delete]: 0,
    [DataOperationType.UpdateViews]: 0,
}

interface StepInfo {
  selection: {
    selectedModel?: DataTableItem,
    selectedReport?: DataTableItem,
  }
  activeStep: number
}

const getReportTwoStepActionInfo = (tableName: string,
                                    activeStep: number,
                                    clickedOn: DataTableItem) : StepInfo => {
  if(tableName === DataTableType.Reports) {
    return {
      selection: {
        selectedReport: clickedOn,
      },
      activeStep: activeStep === 0 ? 1 : activeStep
    }
  }
  else {
    return {
      selection: {},
      activeStep
    }
  }
}

const getCreateStepInfo = (tableName: string,
                          activeStep: number,
                          clickedOn: DataTableItem): StepInfo => {
  if(tableName === DataTableType.Models) {
    return {
      selection: {
        selectedModel: clickedOn,
      },
      activeStep: activeStep === 0 ? 1 : activeStep
    }
  }
  else {
    return {
      selection: {},
      activeStep
    }
  }
}

const getComposeStepInfo = (tableName: string,
                          activeStep: number,
                          clickedOn: DataTableItem): StepInfo => {
  if(tableName === DataTableType.Models) {
    return {
      selection: {
        selectedModel: clickedOn,
      },
      activeStep: activeStep === 0 ? 1 : activeStep
    }
  }
  else if(tableName === DataTableType.Reports) {
    return {
      selection: {
        selectedReport: clickedOn,
      },
      activeStep: activeStep === 1 ? 2 : activeStep
    }
  }
  else {
    return {
      selection: {},
      activeStep
    }
  }
}

const getStepInfo = (op: DataOperationType,
                     tableName: string,
                     activeStep: number,
                     clickedOn: DataTableItem): StepInfo => {
  if (op === DataOperationType.Open)
    return getReportTwoStepActionInfo(tableName, activeStep, clickedOn)
  else if(op === DataOperationType.Create)
    return getCreateStepInfo(tableName, activeStep, clickedOn)
  else if(op === DataOperationType.Compose)
    return getComposeStepInfo(tableName, activeStep, clickedOn)
  else if (op === DataOperationType.Delete)
    return getReportTwoStepActionInfo(tableName, activeStep, clickedOn)
  else if(op === DataOperationType.UpdateViews)
    return getReportTwoStepActionInfo(tableName, activeStep, clickedOn)
  else
    return {
      selection: {},
      activeStep: activeStep
    }
}

export const createDataOperationsSlice: StateCreator<
    DataOperationsSlice,
    [['zustand/devtools', never]],
    [],
    DataOperationsSlice> = (set) => ({
  activeDataOperation: DataOperationType.None,
  numberOfDataOperationSteps: stepsInOperation,
  dataOperationActiveStep: noOpStepValues,
  selectedModel: undefined,
  selectedReport: undefined,
  userRole: undefined,

  setUserRole: (roleName?: string) => set((state) => {
    return {
      ...state,
      userRole: roleName
    }
  }),

  startDataOperation: (action: DataOperationType) => set((state) => {
    return {
      ...state,
      activeDataOperation: action,
    }
  }),

  clearDataOperation: () => set((state) => {
    return {
      activeDataOperation: DataOperationType.None,
      numberOfActionSteps: stepsInOperation,
      dataOperationActiveStep: noOpStepValues,
      selectedModel: undefined,
      selectedReport: undefined,
    }
  }),

  selectTableItem: (tableName: string, clickedOn: DataTableItem) => set((state) => {
    const nextStepInfo = getStepInfo(state.activeDataOperation,
                                tableName,
                                state.dataOperationActiveStep[state.activeDataOperation],
                                clickedOn)
    return {
      ...state,
      dataOperationActiveStep: {
        ...state.dataOperationActiveStep,
        [state.activeDataOperation]: nextStepInfo.activeStep,
      },
      ...{
        selectedModel: state.selectedModel,
        selectedReport: state.selectedReport,
      },
      ...nextStepInfo.selection
    }
  }),
})
