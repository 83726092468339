import { apiClient } from './supabase'
import { DataTableItem } from '../types/datatables'
import { debugMessage } from '../util'

export const dataTablesApi = {
  fetchTableItems: async function() {
    const filesFetchResponse = await apiClient.from("remote_files").select()
    const filesData = filesFetchResponse.data
    const modelsFetchResponse = await apiClient.from("models").select()
    const modelsData = modelsFetchResponse.data
    const reportsFetchResponse = await apiClient.from("reports").select()
    const reportsData = reportsFetchResponse.data

    let fileIdUrlDict: { [key: number]: string } = {}
    if (filesData) {
      filesData.reduce((result, item) => {
        result[item.id as number] = item.uri as string
        return result
      }, fileIdUrlDict)
    }

    let modelFileDict : { [key: number]: number } = {}
    if (modelsData) {
      modelsData.reduce((result, item) => {
        result[item.id as number] = item.content.model.id as number
        return result
      }, modelFileDict)
    }

    let reportModelDict : { [key: number]: number } = {}
    if (reportsData) {
      reportsData.reduce((result, item) => {
        result[item.id as number] = item.model as number
        return result
      }, reportModelDict)
    }

    let modelItems: DataTableItem[] = []
    if (modelsData) {
      modelItems = modelsData.map(item => {
        return {
          ...item,
          model: item.id,
          url: fileIdUrlDict[modelFileDict[item.id as number]],
          views: null,
        } as DataTableItem
      })
    }
    let reportItems: DataTableItem[] = []
    if (reportsData) {
      reportItems = reportsData.map(item => {
        return {
          ...item,
          url: fileIdUrlDict[modelFileDict[item.model as number]],
        } as DataTableItem
      })
    }

    let reportVsItems: DataTableItem[] = []
    if (reportsData) {
      reportVsItems = reportItems.map(item => {
        return {
          ...item,
          viewsString: JSON.stringify(item?.views).substring(0, 15)
        } as DataTableItem
      })
    }

    return {
      models: modelItems,
      reports: reportVsItems
    }
  },

  createNewReportWithProps: async function(name: string, model: number,
                                            owner: string, url: string,
                                            views?: any) {
    const { data, error } = await apiClient.from('reports')
      .insert({ name: name, model: model, owner: owner, views: views })
      .select()
    if (error != null) {
      console.log('Data insert function to backend resulted in error: ')
      console.log(error)
      return undefined
    }
    if (!data) {
      console.log('Data insert function to backend resulted in zero insertions: ')
      console.log(error)
      return undefined
    }

    if (data !== undefined && data.length > 1) {
      console.log('Data insert function to backend resulted in multiple insertions: ')
      console.log(error)
      return undefined
    }

    return {
      ...data[0],
      url: url,
      viewsString: JSON.stringify(data[0]?.views).substring(0, 15),
    } as DataTableItem
  },

  createNewReport: async function(model: DataTableItem, name: string) {
    const obj = await this.createNewReportWithProps(name, model.id, model.owner, model.url)
    return obj
  },

  composeNewReport: async function(model: DataTableItem, report: DataTableItem, name: string) {
    const {data, error} = await apiClient.from('reports')
                                      .select()
                                      .eq('id', report.id)
    if (error != null) {
      console.log('Report not found.')
      console.log(error)
      return undefined
    }
    if (data == null || data.length === 0) {
      console.log('Report not found.')
      return undefined
    }
    const updatedReportViews = data[0].views
    const obj = await this.createNewReportWithProps(name, model.id, model.owner, model.url, updatedReportViews)
    debugMessage(`Composed with ${model.id} ${report.id} ${updatedReportViews}`)
    return obj
  },

  deleteReport: async function(id: number) {
    const { error } = await apiClient.from('reports').delete().eq('id', id)
    if (error != null) {
      console.log('Data deletion in backend resulted in error: ')
      console.log(error)
      return undefined
    }
  },

  updateReportViews: async function(report: DataTableItem, newViewsStr?: string) {
    const getViews = (newViewsStr?: string) => (newViewsStr?JSON.parse(newViewsStr):null)
    const newViews = getViews(newViewsStr)
    const { error } = await apiClient.from("reports").update({ views: newViews }).eq("id", report.id)
    if (error != null) {
      console.log('Views update function to backend resulted in error: ')
      console.log(error)
      return {error: error, views: report.views, viewsString: newViewsStr}
    }
    return {views: newViews, viewsString: newViewsStr}
  },

  addRemotefile : async function(uri : string, owner: string){
    const newRemoteFileData  = { 
      uri: uri, 
      cache_state: "download_required", 
      downloader_type : "auto_detect",
      owner : owner
    };
    
    const { data, error } = await apiClient.from('remote_files').insert(newRemoteFileData).select();

    if (error !== null) {
      console.log('Data insert function to backend (remote_files) resulted in error: ')
      console.log(error)
      return undefined
    }
    if (!data) {
      console.log('Data insert function to backend (remote_files) resulted in zero insertions: ')
      console.log(error)
      return undefined
    }
    if (data !== undefined && data.length > 1) {
      console.log('Data insert function to backend (remote_files) resulted in multiple insertions: ')
      console.log(error)
      return undefined
    }   

    return data;
  },

  addNewmodel : async function(name : string, type : string, uri : string, owner: string){

    const remotefileData = await this.addRemotefile(uri, owner);
    if(remotefileData && remotefileData.length > 0)
    {
      const insertedRowData = remotefileData[0];

      const content  = {
        model: {
          id: insertedRowData.id,
          type: type
        }
      }

      const newModelData  = { 
        name : name,
        content : content,
        owner : owner
      };
      
      const { data, error } = await apiClient.from('models').insert(newModelData).select();

      if (error !== null) {
        console.log('Data insert function to backend (models) resulted in error: ')
        console.log(error)
        return undefined
      }
      if (!data) {
        console.log('Data insert function to backend (models) resulted in zero insertions: ')
        console.log(error)
        return undefined
      }
      if (data !== undefined && data.length > 1) {
        console.log('Data insert function to backend (models) resulted in multiple insertions: ')
        console.log(error)
        return undefined
      }      
      return data;      
    } 
    return undefined   
  },
}
